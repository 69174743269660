import React from 'react';

const StaticPre = () => (
  <div>
	  <div className="block-info">
      <div className="container-narrow">
        <div className="block-info-item">
          <p>¿Querés saber más sobre el pre de Medicina, Odontología y Tec Salud?</p>
          <h3><a target="_blank" rel="noopener noreferrer" href="https://eggeducacion.com/preuniversitarios/">IR A LA WEB ></a></h3>
        </div>
        <div className="block-info-item">
          <p>¿Querés saber más sobre el pre de Ingeniería y Arquitectura?</p>
          <h3><a target="_blank" rel="noopener noreferrer" href="https://eggeducacion.com/preuniversitarios/">IR A LA WEB ></a></h3>
        </div>
        <div className="block-info-item">
          <p>Así funciona nuestro Algoritmo de Cooperación Humana</p>
          <h3><a target="_blank" rel="noopener noreferrer" href="https://eggeducacion.com/metodo-cooperacion/">IR A LA WEB ></a></h3>
        </div>
      </div>
    </div>
    {/* <div className="block-web-link">
      <div className="info">
        <h2>¿No necesitas reunión?</h2>
        <a href='/preinscripciones'><button className="btn-white">PREINSCRIBITE AHORA</button></a>
      </div>
    </div> */}
  </div>
)

export default StaticPre