import React from 'react';
import Layout from '../layouts/Layout';
import CheckCircle from '../images/check-circle.png';

class ResultPage extends React.Component {

	render() {
		return (
			<Layout>
				<div className="container-narrow">
					<div className="result-container">
						<img className="reuniones-logo" src={this.props.logo} alt="" />
						<img className="check-circle" src={CheckCircle} alt=""/>
						<h1>Reunión agendada</h1>
						<span className="grey-span">
							{(!!this.props.nombre?`${this.props.nombre}, t`:"T")+"e esperamos el día:"}
						</span>
						<div className="block-date">
							<div className="bar" />
							<div className="data">
								<span>{this.props.time.format('dddd')}</span>
								<span>{this.props.time.format('LL')}</span>
								<span>{this.props.time.format('[a las] LT')}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="block-info">
					<div className="container-narrow">
						<div className="block-info-item">
							<p>¿Querés saber más sobre el pre de Medicina, Odontología y Tec Salud?</p>
							<h3><a target="_blank" rel="noopener noreferrer" href="https://eggeducacion.com">IR A LA WEB ></a></h3>
						</div>
						<div className="block-info-item">
							<p>¿Querés saber más sobre el pre de Ingeniería y Arquitectura?</p>
							<h3><a target="_blank" rel="noopener noreferrer" href="https://eggeducacion.com">IR A LA WEB ></a></h3>
						</div>
						<div className="block-info-item">
							<p>Así funciona nuestro Algoritmo de Cooperación Humana</p>
							<h3><a target="_blank" rel="noopener noreferrer" href="https://eggeducacion.com">IR A LA WEB ></a></h3>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export default ResultPage