import React, { useState, useEffect } from 'react';
import Layout from '../layouts/Layout';
import CheckCircle from '../images/check-circle.png';

import StaticPre from '../pages/Static/StaticPre'
import StaticCursos from '../pages/Static/StaticCursos'
import StaticCursosChile from '../pages/Static/StaticCursosChile'

const ResultPage = (props) => {

	const [ preData, setPreData ] = useState(undefined)

	useEffect(()=>{
		// eslint-disable-next-line default-case
		switch(props.selectedPre){
			case 'salud':
				setPreData({ name: 'Medicina', price: '11900'})
			// eslint-disable-next-line no-fallthrough
			case 'arquitectura':
			case 'ingenieria':
				setPreData({ name: 'Ingeniería', price: '10900'})	
			// eslint-disable-next-line no-fallthrough
			default:
				return
		}
	},[])


	const getTotal = () => {
		switch(props.selectedPre){
			case 'salud':
				return '11900'
			// eslint-disable-next-line no-fallthrough
			case 'arquitectura':
			case 'ingenieria':
				return '10900'
			// eslint-disable-next-line no-fallthrough
			default:
				return
		}
	}

	const capitalize = s => (typeof s === 'string'?s.split(' ').map(e => e.charAt(0).toUpperCase()+e.slice(1).toLowerCase()).join(' '):s)

	return (
		<Layout>
			<div className="container-narrow">
				<div className="result-container">
					<img className="reuniones-logo" src={props.logo} alt=""/>
					<img className="check-circle" src={CheckCircle} alt=""/>
					<h1>{!!props.nombre ?`¡Gracias, ${props.nombre} por sumarte!`:'¡Gracias por sumarte!'}</h1>
					<span className="grey-span">Estos son los datos para realizar la transferencia/depósito y asegurar tu lugar en el Preuniversitario de {capitalize(props.selectedPre)}</span>
					
					{/* <p className="text-desc"><span>La modalidad de cursado seleccionada es: </span> {props.selectedModo === 'PRESENCIAL' ? 'Presencial' : 'Presencial 100% Online'}</p> */}
					<p className="text-desc"><span>El monto es:</span>
						{getTotal()}
						{/* {props.selectedModo === 'PRESENCIAL' && props.selectedPre === 'salud' ? '$11000' : props.selectedModo === 'PRESENCIAL' && props.selectedPre === 'ingenieria' ? '$9000' : props.selectedModo === 'PRESENCIAL' && props.selectedPre === 'arquitectura' ? '$9000' : ''}
						{props.selectedModo === 'ONLINE' && props.selectedPre === 'salud' ? '$8700' : props.selectedModo === 'ONLINE' && props.selectedPre === 'ingenieria' ? '$7000' : props.selectedModo === 'ONLINE' && props.selectedPre === 'arquitectura' ? '$7000' : ''} */}
					</p>
					<p className="text-desc"><span>Datos para la transferencia:</span></p>

					<div className="transfer-data-list" style={{paddingBottom:'10px'}}>
						<ul>
							<li>Egg SAS</li>
							<li>CUIT: 30716029375</li>
							<li>CBU: 0720229420000000417486</li>
							<li>Cuenta Corriente en pesos Nº 4174/8</li>
							<li>Banco Santander Río</li>
						</ul>
					</div>
					<hr/>
					<p className="text-desc"><span>Importante:</span></p>
					<div className="block-send-reciept">
						<p className="text-desc">Una vez realizada la transferencia o depósito tenés que enviar un mail a <a href="mailto:administracion@eggeducacion.com">administracion@eggeducacion.com</a> con:</p>
						<ul>
							<li>- Comprobante de pago</li>
							<li>- Nombre completo y DNI del titular de la cuenta desde dónde se realizó la transferencia.</li>
							<li>- En el asunto del mail escribí el nombre completo del alumno y el curso al que se inscribió</li>
						</ul>
					</div>
					<p className="text-desc">Al completar el formulario de pre-inscripción estás a un pasito de ser parte de los Preuniversitarios Egg: sólo falta realizar el pago! Reservamos tu lugar por 72 horas para que puedas efectuarlo. Una vez realizado el pago recibirás la confirmación de inscripción y la bienvenida a la experiencia Egg.</p>
				</div>
			</div>
			{props.tipo === 'curso' && <StaticCursos />}
			{props.tipo === 'pre' && <StaticPre />}
			{props.tipo === 'cursoChile' && <StaticCursosChile />}
		</Layout>
	);
}

export default ResultPage