import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import ReactGA from 'react-ga';
import { ReCaptcha } from 'react-recaptcha-v3'

import Layout from '../../layouts/Layout';
import Loader from 'react-loader-spinner';
import ResultPagePreinscripciones from '../../components/ResultPagePreinscripciones';
import ErrorComponent from '../../components/ErrorComponent/ErrorComponent';
import StaticPre from '../Static/StaticPre'
import StaticCursos from '../Static/StaticCursos'
import StaticCursosChile from '../Static/StaticCursosChile'

import PreinscLogo from '../../images/egg.svg';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as yup from "yup";


const Preinscripciones = ({ tipo }) => {

	const schema = yup.object().shape({
		name: yup.string().required('Campo requerido'),
		surname: yup.string().required('Campo requerido') ,
		email: yup.string().required('Campo requerido'),
		dni: yup.string().required('Campo requerido') ,
		phone: yup.string().required('Campo requerido'),
		birthdate: yup.string().required('Campo requerido'),
		gender: yup.string().required('Campo requerido'),
		cuil: yup
			.string()
			.required('Campo requerido')
			.test('len', 'Debe tener 10 caracteres', val => val.length === 11)
			.matches(/^[0-9]+$/, "Deben ser números"),
		address: yup.string().required('Campo requerido'),
		city: yup.string().required('Campo requerido'),
		province: yup.string().required('Campo requerido'),
		comments: yup.string().required('Campo reqerido'),
		//Student data
		parentPhone: yup.string().required('Campo requerido'),
		parentEmail: yup.string().required('Campo requerido'),
		parentName: yup.string().required('Campo requerido') ,
		parentSurname: yup.string().required('Campo requerido'),
		parentDni: yup.string().required('Campo requerido'),
		payment: yup.string().required('Debes seleccionar una opción'),
		installments: yup.string(),
		//Admission data
		modoCursado: yup.string().required('Campo requerido')
	})
	
	const { register, handleSubmit, watch, errors } = useForm({
		mode: 'onBlur',
        resolver: yupResolver(schema),
	});
	
	const capitalize = s => (typeof s === 'string'?s.split(' ').map(e => e.charAt(0).toUpperCase()+e.slice(1).toLowerCase()).join(' '):s)
    
    const [ selectedPre, setSelectedPre ] = useState(tipo.includes('curso') ? 'Elige un curso' : 'Elije un pre',)
	const [ selectedTurn, setSelectedTurn ] = useState('Turno')
	const [ selectedModo, setSelectedModo ] = useState('')
	const [ ready, setReady] = useState(true)
    const [ sent, setSent ] = useState(false)
    const [ error, setError ] = useState('')
	const [ errorDesc, setErrorDesc ] = useState('')
	const [ name, setName ] = useState('')
	const [ dataToSend, setDataToSend ] = useState({})
	const [showInstallments, setShowInstallments] = useState(false)

    useEffect( () => {
        document.title = 'Preinscripciones';
        if (process.env.REACT_APP_NODE_ENV === 'production') {
            ReactGA.initialize(process.env.REACT_APP_GA_ID);
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
	},[])
	
	

	const watchFields = watch(["preList", "preTurn", "payment"]);    
	
	useEffect(()=>{
		setSelectedPre(watchFields.preList)
		if(selectedPre !== 'salud'){
			setSelectedTurn('tarde')
		} else {
			setSelectedTurn(watchFields.preTurn)
		}

		if(watchFields.payment === 'TU_CUOTA'){
			setShowInstallments(true)
		} else {
			setShowInstallments(false)
		}
	},[watchFields])
	

    const selectTurn = (selectedTurn) => {
        setSelectedTurn(selectedTurn)
	}


	const getTurnoId = () => {
		switch(selectedPre){
			case 'salud':
				return 1
			case 'programacion':
				return 4
			case 'marketing':
				return 5
			case 'preChile':
				return 6
			case 'programacionChile':
				return 7
			case 'ingenieria':
				return 2
			case 'arquitectura': 
				return 2
			default:
				return null
		}
	}

	const onSubmit = async data => {
		
		setName(capitalize(data.name))

		const dataToSend = {
			//Student
			nombre: data.name,
			apellido: data.surname,
			email: data.email,
			documento: data.dni.replace('+', '').replace('.', ''),
			telefono: data.phone.replace('+', '').replace('.', ''),
			fechaNacimiento: data.birthdate,
			genero: data.gender,
			cuil: data.cuil,
			direccion: data.address,
			ciudad: data.city, 
			provincia: data.province, 
			observaciones: data.comments,
			//Parent
			telefonoPadre: data.parentPhone.replace('+', '').replace('.', ''),
			nombrePadre: data.parentName,
			apellidoPadre: data.parentSurname,
			dniPadre: data.parentDni,
			parentEmail: data.parentEmail,
			//Admission
			preuniversitario: selectedPre.charAt(0),
			selectedPre: selectedPre,
			tipo: 'INSCRIPCION_2022',
			modoCursado: data.modoCursado,
			formaPago: data.payment,
			idTurno: (getTurnoId()),
			horarioTurno: selectedTurn,
		}

		setDataToSend(dataToSend)

		try {
			setReady(false)
			const response = await Axios.post(`${process.env.REACT_APP_API}api/ficha/alumno`, dataToSend)

			if (response.data.mensaje === "preinscripto") {
				if(data.payment === 'TU_CUOTA'){
					if(data.installments === '1'){
						switch(selectedPre){
							case 'salud':
								if( data.modoCursado === 'PRESENCIAL'){
									return window.open(`https://tucuota.com/link/9a3002e0-3828-11ec-991c-afde43429192?customer_id=&customer_email=${dataToSend.email}&customer_name=${dataToSend.nombre}`)
								} else {
									return window.open(`https://tucuota.com/link/f9581e20-3828-11ec-aafd-cd6c9ada9cdb?customer_id=&customer_email=${dataToSend.email}&customer_name=${dataToSend.nombre}`)
								}
							case 'ingenieria':
								if( data.modoCursado === 'PRESENCIAL'){
									return window.open(`https://tucuota.com/link/3ad6b120-3829-11ec-bce4-e931917ad26f?customer_id=&customer_email=${dataToSend.email}&customer_name=${dataToSend.nombre}`)
								} else {
									return window.open(`https://tucuota.com/link/b57d5580-3829-11ec-8707-01b7c5483de9?customer_id=&customer_email=${dataToSend.email}&customer_name=${dataToSend.nombre}`)
								}
							case 'arquitectura':
								if( data.modoCursado === 'PRESENCIAL'){
									return window.open(`https://tucuota.com/link/3ad6b120-3829-11ec-bce4-e931917ad26f?customer_id=&customer_email=${dataToSend.email}&customer_name=${dataToSend.nombre}`)
								} else {
									return window.open(`https://tucuota.com/link/b57d5580-3829-11ec-8707-01b7c5483de9?customer_id=&customer_email=${dataToSend.email}&customer_name=${dataToSend.nombre}`)
							}
	
							default:
								return
						}
					} else if (data.installments === '3') {
						switch(selectedPre){
							case 'salud':
								if( data.modoCursado === 'PRESENCIAL'){
									return window.open(`https://mpago.la/1dLgpkF`)
								} else {
									return window.open(`https://mpago.la/3127nbQ`)
								}
							case 'ingenieria':
								if( data.modoCursado === 'PRESENCIAL'){
									return window.open(`https://mpago.la/2y7szr6`)
								} else {
									return window.open(`https://mpago.la/2M64GCG`)
								}
							case 'arquitectura':
								if( data.modoCursado === 'PRESENCIAL'){
									return window.open(`https://mpago.la/2y7szr6`)
								} else {
									return window.open(`https://mpago.la/2M64GCG`)
							}
	
							default:
								return
						}
					}
					
				} else if(data.payment === 'TRANSFERENCIA') {
					setSelectedPre(selectedPre)
					setSelectedModo(data.modoCursado)
					setSent(true)	 
				}
			} else {
				// Ha ocurrido un error!
				setSent(false)
				setError('No se pudo concluir la preinscripción')
				setErrorDesc('Por favor, intente nuevamente más tarde.')
			} 
		} catch (error) {
			setError('Hubo un error de comunicación')
			setErrorDesc('Por favor, revisa tu conexión a internet.')
			setSent(false)
		} finally {
			setReady(true)
		}
		
	}
	
	const renderOptions = () => {
		const morning   = <option key="mañana" value="mañana">Mañana</option>
		const afternoon = <option key="tarde"  value="tarde" >Tarde</option>
		switch (selectedPre) {
			case 'salud':
				return [morning, afternoon]
			case 'ingenieria':
			case 'arquitectura':
			//this.setState({selectedTurn: 'tarde'})
				return afternoon
			case 'programacion':
				return morning
			case 'marketing':
				return afternoon
			case 'preChile':
				return afternoon
			default:
				return null
		}
	}

    if(!ready){
        return <Layout><div className="loading-page-container"><Loader type="Watch" color="#FFCD00" height={50} width={50} /></div></Layout>
      }
  
    if(sent){
        return <ResultPagePreinscripciones nombre={name} tipo={tipo} logo={PreinscLogo} selectedPre={dataToSend.selectedPre} selectedModo={dataToSend.modoCursado} />
	}
	
    return(
        <Layout>
        <ReCaptcha
          sitekey="6LecXr8UAAAAAIuNIyqXrguIzQn5o2mlpQ9Aq2Ct"
          action='preinscripciones'
          //verifyCallback={token => console.log('Token: ', token)}
        />
        <div className="container-narrow">
          <div className="preinscripciones-container">
            <div className="preinscripciones-heading">
				<img className="preinscripciones-logo" src={PreinscLogo} alt=""/>
				<div className="preinscripciones-title">
					<h1>Preinscribite</h1>
				</div>
				<span className="title-desc">Completá el formulario</span>
            </div>
            <form className="preinscripciones-form" onSubmit={handleSubmit(onSubmit)}>

				<ErrorComponent id="error"
					error={error}
					errorDesc={errorDesc}
					onClose={() => { setError('') }}
				/>

				<div className="block">
					<h2>Selecciona el curso</h2>
					<div className="inputs-grid">
						<div className="info-item">
							<select name="preList" className="select-input" ref={register}>
							<option value="">Seleccione un preuniversitario</option>
							{tipo === 'pre' && <option value="salud">Salud</option>}
							{tipo === 'pre' && <option value="ingenieria">Ingeniería</option>}
							{tipo === 'pre' && <option value="arquitectura">Arquitectura</option>}
							{tipo === 'curso' && <option value="programacion">Programación</option>}
							{tipo === 'curso' && <option value="marketing">Marketing Digital</option>}
							{tipo === 'cursoChile' && <option value="preChile">Preuniversitario PSU</option>}
							{tipo === 'cursoChile' && <option value="programacionChile">Programación</option>}
							</select>
						</div>
						<div className="info-item">
							<select 
								className="select-input"
								name="preTurn"
								ref={register}
								disabled={selectedPre === "Preuniversitario" || selectedPre === "Curso"}
							>
								<option value="">Selecciona un Turno</option>
							{renderOptions()}
							</select>
						</div>
					</div>
					<div className="inputs-grid">
						<div className="info-item">
							<select name="modoCursado" className="select-input" ref={register} >
								<option value="" >Elige una modalidad de cursado</option>
								<option value="PRESENCIAL">Presencial</option>
								<option value="ONLINE">Presencial 100% Online</option>
							</select>
							{errors.modoCursado && <span className="input-error">{errors.modoCursado.message}</span>}
						</div>
					</div>
				</div>
				<div className="block">
					<p className="description"><span className="bold">¿Qué diferencia hay entre la modalidad presencial y presencial 100% online?</span> Lo único que cambia es el lugar donde vas a estudiar: tu casa o nuestra sede ¿Pero todo lo demás es igual? Si! Material de trabajo, acompañamiento de profes y coachs, horarios, talleres, todo es igual. La cuarentena nos motivó a trasladar todos los esfuerzos al mundo online para que nuestros alumnos pudieran continuar con su experiencia de aprendizaje Egg a pesar de las distancias, es por eso que los resultados académicos que se obtienen en ambas modalidades son los mismos, ya lo hemos comprobado. Por ello seguiremos ofreciendo el plan online para estudiantes que vivan más lejos o prefieran estudiar dese casa.</p>

					<p className="description"><span className="bold">En orden de llegada.</span> Nuestro espacio físico tiene un límite, es decir que hay un cupo máximo de alumnos que podrán asistir a nuestra sede, pero como queremos que cumplas tu objetivo y expandas tus límites, te acompañamos y también rompemos nuestras propias limitantes: las puertas de de nuestro espacio virtual están abiertas de par en par para recibirlos!</p>
				</div>
				

				<div className="block">
					<h2>Datos Personales</h2>

					<div className="inputs-grid">
						<div className="info-item">
							<span className="block-desc">Nombre</span>
							<input type="text" className="capitalize" name="name" ref={register}/>
							{errors.name && <span className="input-error">{errors.name.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">Apellido</span>
							<input type="text" className="capitalize"  name="surname" ref={register}/>
							{errors.surname && <span className="input-error">{errors.surname.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">{tipo.includes('cursoChile') ? "RUT" : "DNI"}</span>
							<input type="number" name="dni" ref={register}/>
							{errors.dni && <span className="input-error">{errors.dni.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">Fecha de nacimiento</span>
							<input type="date" name="birthdate" placeholder="dd/mm/aaaa" ref={register}/>
							{errors.birthdate && <span className="input-error">{errors.birthdate.message}</span>}
						</div>
					</div>
					<div className="inputs-grid">
						<div className="info-item w-100">
							<span className="block-desc">CUIL (Sin guiónes)</span>
							<input type="text" name="cuil" ref={register}/>
							{errors.cuil && <span className="input-error">{errors.cuil.message}</span>}
							<p style={{marginTop:'15px', fontSize:'13px'}}><a target="_blank" rel="noopener noreferrer" href="https://www.argentina.gob.ar/descarga-constancia-cuil">Buscá acá tu número de CUIL</a></p>
						</div>
					</div>
					<div className="inputs-grid">
						<div className="info-item">
							<span className="block-desc">Género</span>
							<select name="gender" className="select-input" ref={register}>
								<option value="">Selecciona tu género</option>
								<option value="male">Masculino</option>
								<option value="female">Femenino</option>
								<option value="other">Otro</option>
								<option value="pnts">Prefiero no decir</option>
							</select>
							{errors.gender && <span className="input-error">{errors.gender.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">Teléfono</span>
							<input type="tel" name="phone" ref={register}/>
							{errors.phone && <span className="input-error">{errors.phone.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">Correo electrónico</span>
							<input type="email" name="email" ref={register}/>
							{errors.email && <span className="input-error">{errors.email.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">Dirección</span>
							<input type="text" name="address" ref={register}/>
							{errors.address && <span className="input-error">{errors.address.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">Ciudad</span>
							<input type="text" name="city" ref={register}/>
							{errors.city && <span className="input-error">{errors.city.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">Provincia</span>
							<input type="text" name="province" ref={register}/>
							{errors.province && <span className="input-error">{errors.province.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">¿Por qué elegís estudiar en Egg?</span>
							<textarea name="comments" id="comments" cols="30" rows="10" maxLength={399} ref={register}></textarea>
							{errors.comments && <span className="input-error">{errors.comments.message}</span>}
						</div>
					</div>
				</div>

				{(tipo.includes('pre') || selectedPre === 'preChile' ) && <div className="block">
					<h2>Datos padre, madre o tutor</h2>
					<div className="inputs-grid">
						<div className="info-item">
							<span className="block-desc">Nombre</span>
							<input type="text" className="capitalize" name="parentName" ref={register}/>
							{errors.parentName && <span className="input-error">{errors.parentName.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">Apellido</span>
							<input type="text" className="capitalize" name="parentSurname" ref={register}/>
							{errors.parentSurname && <span className="input-error">{errors.parentSurname.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">{tipo.includes('cursoChile') ? "RUT" : "DNI"}</span>
							<input type="text" className="capitalize" name="parentDni" ref={register}/>
							{errors.parentDni && <span className="input-error">{errors.parentDni.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">Número de contacto</span>
							<input type="text" name="parentPhone" ref={register}/>
							{errors.parentPhone && <span className="input-error">{errors.parentPhone.message}</span>}
						</div>
						<div className="info-item">
							<span className="block-desc">Email</span>
							<input type="text" name="parentEmail" ref={register}/>
							{errors.parentEmail && <span className="input-error">{errors.parentEmail.message}</span>}
						</div>
					</div>
				</div>}
				

				<div className="block">
					<h2>Forma de pago</h2>
					<div className="inputs-grid">
						<div className="info-item">
							<select name="payment" className="select-input" ref={register}>
								<option value="">Selecciona tu medio de pago</option>
								<option value="TU_CUOTA">Débito o crédito</option>
								<option value="TRANSFERENCIA">Transferencia o depósito</option>
							</select>
							{errors.payment && <span className="input-error">{errors.payment.message}</span>}
						</div>
					</div>
					{showInstallments && <div className="inputs-grid">
						<div className="info-item">
							<select name="installments" className="select-input" ref={register}>
								<option value="">Selecciona cantidad de cuotas</option>
								<option value="1">1</option>
								<option value="3">3</option>
							</select>
							{errors.installments && <span className="input-error">{errors.installments.message}</span>}
						</div>
					</div>}
				</div>

				<div className="disclaimer-block">
					<p>Importante: Al completar el formulario de pre-inscripción estás a un pasito de ser parte de los Preuniversitarios Egg: sólo falta realizar el pago! Reservamos tu lugar por 72 horas para que puedas efectuarlo. Una vez realizado el pago recibirás la confirmación de inscripción y la bienvenida a la experiencia Egg.</p>
				</div>

				<div className="block-submit">
					<button type="submit" className="btn-submit">PRE INSCRIBIR</button>
				</div>

            </form>
          </div>
        </div>
        {tipo === 'curso' && <StaticCursos />}
        {tipo === 'pre' && <StaticPre />}
        {tipo === 'cursoChile' && <StaticCursosChile />}
      </Layout>
    )
}

export default Preinscripciones;