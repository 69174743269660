import React from 'react'
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ErrorComponent extends React.Component{

    render() {
        if (!this.props.error)
            return null

        return <div id={this.props.id} className="block-error">
            <FontAwesomeIcon className="error-btn" icon={faTimesCircle} onClick={this.props.onClose}/>
            <div className="error-msg">
                {this.props.error}
            </div>
            {!!this.props.errorDesc &&
                <div className="error-desc">
                    {this.props.errorDesc}
                </div>
            }
        </div>
    }
}

export default ErrorComponent