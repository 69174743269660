import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/Layout';

class DashboardPage extends React.Component {

	render() {
		return (
			<Layout>
				<ul>
					<li><Link to="/reuniones">Reuniones Pre</Link></li>
					<li><Link to="/preinscripciones">Preinscripciones Pre 2020</Link></li>
					<li><Link to="/preinscripciones2021">Preinscripciones Pre 2021</Link></li>
					<li><Link to="/preinscripciones2022">Preinscripciones Pre 2022</Link></li>
					<li><Link to="/preinscripciones2023">Preinscripciones Pre 2023</Link></li>
					<li><Link to="/repasos">Repasos Salud</Link></li>
					<li><Link to="/reuniones/cursos">Reuniones Cursos</Link></li>
					<li><Link to="/preinscripciones/cursos">Preinscripciones Cursos</Link></li>
					<li><Link to="/reuniones/cursos/chile">Reuniones Cursos Chile</Link></li>
					<li><Link to="/preinscripciones/cursos/chile">Preinscripciones Cursos Chile</Link></li>
				</ul>
			</Layout>
		);
	}
}

export default DashboardPage;