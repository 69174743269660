import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import DashboardPage from '../pages/Dashboard/DashboardPage'
import Preinscripciones from '../pages/Preinscripciones/Preinscripciones'
import Preinscripciones2021 from '../pages/Preinscripciones/Preinscripciones2021'
import Preinscripciones2022 from '../pages/Preinscripciones/Preinscripciones2022'
import Preinscripciones2023 from '../pages/Preinscripciones/Preinscripciones2023'
import Reuniones from '../pages/Reuniones/Reuniones'
import Repasos from '../pages/Repasos/Repasos'
import NotFoundPage from '../pages/NotFound/NotFoundPage'

const createHistory = require("history").createBrowserHistory;
export const history = createHistory();

const AppRouter = (props) => {
	return(
		<Router history={history}>
			<Switch>
				<Route path="/" exact component={DashboardPage}></Route>

				<Route 
				path="/preinscripciones" 
				exact={true} 
				render={(props) => <Preinscripciones {...props} tipo="pre" />}
				></Route>

				<Route 
				path="/preinscripciones2021" 
				exact={true} 
				render={(props) => <Preinscripciones2021 {...props} tipo="pre" />}
				></Route>

				<Route 
				path="/preinscripciones2022" 
				exact={true} 
				render={(props) => <Preinscripciones2022 {...props} tipo="pre" />}
				></Route>

				<Route 
				path="/preinscripciones2023" 
				exact={true} 
				render={(props) => <Preinscripciones2023 {...props} tipo="pre" />}
				></Route>

				<Route 
				path="/repasos" 
				exact={true} 
				render={(props) => <Repasos {...props} tipo="pre" />}
				></Route>

				<Route 
				path="/preinscripciones/cursos" 
				exact={true} 
				render={(props) => <Preinscripciones {...props} tipo="curso" />}
				></Route>

				<Route 
				path="/preinscripciones/cursos/chile" 
				exact={true} 
				render={(props) => <Preinscripciones {...props} tipo="cursoChile" />}
				></Route>

				<Route 
				path="/reuniones" 
				exact={true} 
				render={(props) => <Reuniones {...props} tipo="pre" />}
				></Route>

				<Route 
				path="/reuniones/cursos" 
				exact={true} 
				render={(props) => <Reuniones {...props} tipo="curso" />}
				></Route>

				<Route 
				path="/reuniones/cursos/chile" 
				exact={true} 
				render={(props) => <Reuniones {...props} tipo="cursoChile" />}
				></Route>

				<Route path="*" component={NotFoundPage}></Route>
			</Switch>
		</Router>
	)
}

export default AppRouter