import React from 'react'

import { DatePicker as AntdPicker, Select } from 'antd'
import moment from 'moment'
import * as horario from '../../horarios'
import locale from 'antd/es/date-picker/locale/es_ES';

class DatePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dia: null,
            hora: null,
            turnos: null,
        }
    }
    
    setTurnos = () => {
        // console.log(horario)
        // console.log(this.props)
        let turnos = {}
        const citas = horario[this.props.turno].citas
        const hoy = (new Date()).toISOString().split('T')[0]
        //console.log("Hoy es ", hoy)
        for (let i = 0; i<citas.length; i++) {
            const fecha = citas[i].fecha.substring(0,10)
            // Add fecha only if it is in the future
            if (fecha > hoy){
                turnos[fecha] = {}
                do {
                    turnos[fecha][citas[i].hora] = citas[i].id
                    i++;
                } while (i<citas.length && fecha === citas[i].fecha.substring(0,10))
                i--;
            }
        }
        this.setState({turnos})
    }

    // Para mostrar si es en la mañana o en la tarde
    isMorning(hora) {
        return hora < '12:00' ? 'Mañana' : 'Tarde'
    }

    // Marca los días válidos si están en el arreglo horario.turno
    validDate = current => {
        if (!!this.state.turnos)
            return !!this.state.turnos[(current.format('YYYY-MM-DD'))]
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.turno !== prevProps.turno) {
            this.setTurnos();
            this.update(undefined, undefined)
        }
    }
    
    renderHorarioOptions= () => {
        let options = []
        const {turnos, dia} = this.state
        if (!turnos || !turnos[dia]) return null
        for(let key in turnos[dia]) {
            options.push(
                <Select.Option key={key} value={key}>
                    {`${this.isMorning(key)} (${key})`}
                </Select.Option>
            )
        }
        return options
    }

    update = (dia = null, hora = null) => {
        this.setState({ dia, hora}, () => {
            const { dia, hora, turnos } = this.state
            this.props.onUpdate( (!turnos || !dia || !turnos[dia] || !hora)?undefined:turnos[dia][hora], dia, hora)
        })
    }

    render() {
        if (!this.props.turno || !this.state.turnos) {
            return <div className="datepicker-component">
                <div className="datepicker-container">
                    <AntdPicker disabled className="datepicker-input" placeholder="Seleccionar fecha"/>
                </div>
                <div className="turnpicker-container">
                    <Select className="select-input" defaultValue="Seleccionar turno" disabled/>
                </div>
            </div>
        }
        return <div className="datepicker-component">
            <div className="datepicker-container">
                <AntdPicker
                    format="dddd, DD [de] MMMM"
                    disabledDate={current => !this.validDate(current)} //desactivado los días inválidos
                    onChange={ dia => this.update(dia.format('YYYY-MM-DD')) }
                    value={this.state.dia?moment(this.state.dia, 'YYYY-MM-DD'):null}
                    locale={locale}
                    allowClear={false}
                    showToday={false}
                    className="datepicker-input"
                />
            </div>
            <div className="turnpicker-container">
                <Select
                    value={this.state.hora?this.state.hora:"Seleccionar turno"}
                    disabled={!this.state.dia}
                    onChange={ hora => this.update(this.state.dia, hora)}
                    className="select-input"
                >
                    {this.renderHorarioOptions()}
                </Select>
            </div>
        </div>
    }
}

export default DatePicker