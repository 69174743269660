import React from 'react';

class NotFoundPage extends React.Component {

	render() {
		return (
			<div>NotFoundPage</div>
		);
	}
}

export default NotFoundPage;