import React from 'react';

import AppRouter from './router/AppRouter'
import { ConfigProvider } from 'antd';
import { loadReCaptcha } from 'react-recaptcha-v3'

import 'antd/dist/antd.css';
import es from 'antd/es/locale/es_ES';
import 'moment/locale/es';

class App extends React.Component {

	componentDidMount(){
		// loadReCaptcha('6LecXr8UAAAAAIuNIyqXrguIzQn5o2mlpQ9Aq2Ct')
	}

	render () {
		return (
			<ConfigProvider locale={es}>
	  			<AppRouter />
			</ConfigProvider>
	  	);
	}
}

export default App;
