import React from 'react';

const Layout = (props) => {
	return (
		<div className="app-container-wide">
				{props.children}
		</div>
	);
}

export default Layout
