import React from 'react';
import Axios from 'axios';
import ReactGA from 'react-ga';
import moment from 'moment';
import { ReCaptcha } from 'react-recaptcha-v3'

import Layout from '../../layouts/Layout';
import Loader from 'react-loader-spinner';
import DatePicker from '../../components/DatePicker/DatePicker';
import ResultPageReuniones from '../../components/ResultPageReuniones';
import ErrorComponent from '../../components/ErrorComponent/ErrorComponent';
import StaticPre from '../Static/StaticPre'
import StaticCursos from '../Static/StaticCursos'
import StaticCursosChile from '../Static/StaticCursosChile'

import ReunionLogo from '../../images/egg.svg';

class Reuniones extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedPre: '',
			selectedTurn: null,
			turnDia: '',
			turnHora: '',
			ready: true,
			sent: false,
			error: '',
			errorDesc: ''
		}
		this.submit = this.submit.bind(this);
		this.firstname = React.createRef();
		this.surname = React.createRef();
		this.mail = React.createRef();
		this.phone = React.createRef()
	}

	componentDidMount() {
		document.title = 'Reuniones';
		if (process.env.REACT_APP_NODE_ENV === 'production') {
    	  ReactGA.initialize(process.env.REACT_APP_GA_ID);
    	  ReactGA.pageview(window.location.pathname + window.location.search);
    	}
	}

	// Guarda en state el value del botón seleccionado
	selectPre = (e) => {
		const selectedPre = e.target.name;
		this.setState(() => {
			return {
				selectedPre
			}
		})
	}

	selectTurn = (id, dia, hora) => {
		this.setState({ selectedTurn: id, turnDia: dia, turnHora: hora })
	}

	capitalize = s => (typeof s === 'string' ? s.split(' ').map(e => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()).join(' ') : s)

	submit = (event) => {
		let nombre = this.capitalize(this.firstname.current.value)
		this.setState({ nombre })
		let apellido = this.capitalize(this.surname.current.value)
		let mail = this.mail.current.value
		let tel = this.phone.current.value.replace('+', '').replace('.','')
		let idCita = this.state.selectedTurn
		let preuniversitario = this.state.selectedPre.charAt(0)
		let log = { nombre, apellido, mail, tel, idCita, preuniversitario }
		
		if ( Object.values(log).some(e => !e) ) {	//Check something empty
			return this.setState({ 
				error: 'Datos incompletos', 
				errorDesc: 'Por favor, completa todos los campos para agendar una reunión.'
			})
		} else if (!mail.includes('@')) {
			return this.setState({
				error: 'Correo electrónico inválido',
				errorDesc: 'Por favor, completa con una dirección de correo válida.'
			})
		} else if (isNaN(tel)) {
			return this.setState({
				error: 'Número de teléfono inválido',
				errorDesc: 'Por favor, completa con un número de teléfono válido.'
			})
		}

		// Comment this line for more fun!
		//return this.setState({ sent: true })

		this.setState({ ready: false })
		Axios.post(`${process.env.REACT_APP_API}api/cita`, log).then((response) => {
			
			if (response.data.mensaje === "citado") {
				this.setState({ sent: true, ready: true })
				process.env.REACT_APP_NODE_ENV==='production' && ReactGA.event({
					category: 'Reunión',
					action: 'Reservar',
					label: 'reservar',
					tipo: this.props.tipo
					//value: parseInt(idCita)
				});
			} else {
				// Ha ocurrido un error!
				this.setState({ 
					sent: false, 
					ready: true, 
					selectedPre: '',
					error: 'No se pudo concluir la cita',
					errorDesc: 'Por favor, intente nuevamente más tarde.'
				})
			}
		}).catch((response) => {
			this.setState({
				error: 'Hubo un error de comunicación',
				errorDesc: 'Por favor, revisa tu conexión a internet.', 
				sent: false, 
				ready: true, 
				selectedPre: ''
			})
		})
	}

	render() {

		if (!this.state.ready) {
			return <Layout>
				<div className="loading-page-container">
					<Loader type="Watch" color="#FFCD00" height={50} width={50} />
				</div>
			</Layout>
		}

		if (this.state.sent) {
			return <ResultPageReuniones
				logo={ReunionLogo}
				time={moment(this.state.turnDia + ' ' + this.state.turnHora, 'YYYY-MM-DD hh:mm')}
				nombre={this.state.nombre}
			/>
		}

		return (
			<Layout>
				<div className="container-narrow">
					<div className="reuniones-container">
						<div className="reuniones-heading">
							<img className="reuniones-logo" src={ReunionLogo} alt="" />
							<div className="reuniones-title">
								<h1>Agendar reunión</h1>
							</div>
							<span className="title-desc">Te invitamos a conocernos</span>
						</div>
						<div className="reuniones-form">
							<div className="block-one">
								<h2>Quiero una reunión para saber sobre</h2>
								<div className="block-one-buttons">
									{this.props.tipo === "pre" && <input type="button" className={this.state.selectedPre === "salud" ? 'selected' : ''} onClick={this.selectPre} value="Salud (*)" name="salud" />}
									{this.props.tipo === "pre" && <input type="button" className={this.state.selectedPre === "ingenieria" ? 'selected' : ''} onClick={this.selectPre} value="Ingeniería" name="ingenieria" />}
									{this.props.tipo === "pre" && <input type="button" className={this.state.selectedPre === "arquitectura" ? 'selected' : ''} onClick={this.selectPre} value="Arquitectura" name="arquitectura" />}
									{this.props.tipo === "curso" && <input type="button" className={this.state.selectedPre === "programacion" ? 'selected' : ''} onClick={this.selectPre} value="Programación" name="programacion" />}
									{this.props.tipo === "curso" && <input type="button" className={this.state.selectedPre === "marketing" ? 'selected' : ''} onClick={this.selectPre} value="Marketing" name="marketing" />}
									{this.props.tipo === "cursoChile" && <input type="button" className={this.state.selectedPre === "preChile" ? 'selected' : ''} onClick={this.selectPre} value="PSU" name="preChile" />}
									{this.props.tipo === "cursoChile" && <input type="button" className={this.state.selectedPre === "programacionChile" ? 'selected' : ''} onClick={this.selectPre} value="Programación" name="programacionChile" />}
								</div>
							<div className="aclaracion-salud">
								{this.props.tipo === "pre" && <span>(*) Medicina, Odontología y Tec. en Salud.</span>}
							</div>
							</div>
							<div className="block-two">
								<h2>Puedo asistir el día</h2>
								<div className="block-two-buttons">
									{/* Acá le estoy pasando el item seleccionado al datepicker" */}
									<DatePicker turno={this.state.selectedPre} onUpdate={this.selectTurn} />
								</div>
							</div>
							<div className="block-three">
								<h2>Tus datos</h2>
								<div className="block-three-inputs">
									<div className="info-item">
										<input className="capitalize" type="text" placeholder="Nombre" ref={this.firstname} />
									</div>
									<div className="info-item">
										<input className="capitalize" type="text" placeholder="Apellido" ref={this.surname} />
									</div>
									<div className="info-item">
										<input type="email" placeholder="Correo electrónico" ref={this.mail} />
									</div>
									<div className="info-item">
										<input type="number" placeholder="Teléfono" ref={this.phone} />
									</div>
								</div>
							</div>
							<ErrorComponent id="error"
								error={this.state.error} 
								errorDesc={this.state.errorDesc} 
								onClose={() => {this.setState({error: ''})}} 
							/>
							<ReCaptcha
								sitekey="6LecXr8UAAAAAIuNIyqXrguIzQn5o2mlpQ9Aq2Ct"
								action='reuniones'
							//verifyCallback={token => console.log('Token: ', token)}
							/>
							<div className="block-submit" onClick={this.submit}>
								<button type="submit" valie="Submit" className="btn-submit">AGENDAR REUNIÓN</button>
							</div>
						</div>
					</div>
				</div>
				{this.props.tipo === 'curso' && <StaticCursos />}
        		{this.props.tipo === 'pre' && <StaticPre />}
        		{this.props.tipo === 'cursoChile' && <StaticCursosChile />}
			</Layout>
		);
	}
}

export default Reuniones;