import React, { useState } from 'react';
import Layout from '../layouts/Layout';
import CheckCircle from '../images/check-circle.png';

import StaticPre from '../pages/Static/StaticPre'
import StaticCursos from '../pages/Static/StaticCursos'
import StaticCursosChile from '../pages/Static/StaticCursosChile'

const ResultPage = (props) => {

	console.log(props)

	const getPre = () => {
		switch(props.selectedPre){
			case 'repasos-salud':
				if(props.modo === 'PRESENCIAL'){
					return { name: 'Repasos Salud', price: '14000'}
				} else {
					return { name: 'Repasos Salud', price: '11000'}
				}
			case 'repasos-ingenieria':
				if(props.modo === 'PRESENCIAL'){
					return { name: 'Repasos Ingeniería y Arquitectura', price: '11000'}					
				}
				break
			default: 
				return null
		}
	}

	const [ preData ] = useState(getPre())

	return (
		<Layout>
			<div className="container-narrow">
				<div className="result-container">
					<img className="reuniones-logo" src={props.logo} alt=""/>
					<img className="check-circle" src={CheckCircle} alt=""/>
					<h1>{!!props.nombre ?`¡Gracias, ${props.nombre} por sumarte!`:'¡Gracias por sumarte!'}</h1>
					<span className="grey-span">Estos son los datos para realizar la transferencia/depósito y asegurar tu lugar en los repasos</span>
					
					<p className="text-desc"><span>El monto es:</span> ${preData.price}</p>
					<p className="text-desc"><span>Datos para la transferencia:</span></p>

					<div className="transfer-data-list" style={{paddingBottom:'10px'}}>
						<ul>
							<li>Egg SAS</li>
							<li>CUIT: 30716029375</li>
							<li>CBU: 0720229420000000417486</li>
							<li>Alias: eggsas1939</li>
							<li>Cuenta Corriente en pesos Nº 4174/8</li>
							<li>Banco Santander Río</li>
						</ul>
					</div>
					<hr/>
					<p className="text-desc"><span>Importante:</span></p>
					<div className="block-send-reciept">
						<p className="text-desc">Una vez realizada la transferencia o depósito tenés que enviar un mail a <a href="mailto: campus_egg@eggcooperation.com">campus_egg@eggcooperation.com</a> con:</p>
						<ul>
							<li>- Comprobante de pago</li>
							<li>- Nombre completo y DNI del titular de la cuenta desde dónde se realizó la transferencia.</li>
							<li>- En el asunto del mail escribí el nombre completo del alumno y el curso al que se inscribió</li>
						</ul>
					</div>
					<p className="text-desc">Tu lugar no está 100% asegurado hasta que completes el pago de la inscripción. Guardaremos tu reserva por 72 horas para que puedas hacerlo. Una vez que pagues, recibirás la confirmación de inscripción.</p>
				</div>
			</div>
			{props.tipo === 'curso' && <StaticCursos />}
			{props.tipo === 'pre' && <StaticPre />}
			{props.tipo === 'cursoChile' && <StaticCursosChile />}
		</Layout>
	);
}

export default ResultPage