import React from 'react';

const StaticCursosChile = () => (
	<div>
    <div className="block-info">
      <div className="container-narrow">
        <div className="block-info-item" style={{flexBasis:'50%'}}>
          <p>¿Querés saber más sobre el curso de Programación</p>
          <h3><a target="_blank" rel="noopener noreferrer" href="https://eggeducacion.cl/cursos/curso-programacion/">IR A LA WEB ></a></h3>
        </div>
        <div className="block-info-item" style={{flexBasis:'50%'}}>
          <p>¿Querés saber más sobre el preuniversitario PSU</p>
          <h3><a target="_blank" rel="noopener noreferrer" href="https://eggeducacion.cl/preuniversitarios/">IR A LA WEB ></a></h3>
        </div>
      </div>
    </div>
    <div className="block-web-link">
      <div className="info">
        <h2>¿No necesitas reunión?</h2>
        <a href='/preinscripciones/cursos/chile'><button className="btn-white">PREINSCRIBITE AHORA</button></a>
      </div>
    </div>
  </div>
)

export default StaticCursosChile